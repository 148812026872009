export default class NewsletterSubscriptionForm {
    constructor() {
        this.forms = document.querySelectorAll('.js-newsletter-subscription-form');
        if (this.forms.length > 0) {
            this.initialize();
        }
    }

    initialize = () => {
        this.forms.forEach((form) => {
            let email = form.querySelector('input[type=email]');
            email.placeholder = 'email';
        })
    };
}