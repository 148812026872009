import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import NewsletterSubscriptionForm from './js/blocks/express_form/templates/newsletter-subscription-form';
import ContactForm from "./js/blocks/express_form/templates/contact-form";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    initialize = () => {

        new NewsletterSubscriptionForm();
        new ContactForm();

        let ccm = document.querySelector('.ccm-page');
        if (ccm.classList.contains('is-logged-in')) {

        }

        //if (!ccm.classList.contains('is-edit-mode') && !ccm.classList.contains('is-logged-in')) {

            let sections = gsap.utils.toArray('.screen');

            ScrollTrigger.matchMedia({
                //'(min-width: 992px) and (min-height: 900px)': () => {
                '(min-width: 768px)': () => {
                    console.log('Screen - horizontal mode');

                    let panelsContainer = document.querySelector('.screen-wrapper'), tween;
                    document.querySelectorAll('.js-book a').forEach(anchor => {

                        anchor.addEventListener("click", (e) => {
                            e.preventDefault();

                            let targetElem = document.querySelector(e.target.getAttribute("href")),
                                y = targetElem;

                            console.log(y);

                            if (targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
                                let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start,
                                    totalMovement = (sections.length - 1) * targetElem.offsetWidth;
                                y = Math.round(tween.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);
                            }

                            gsap.to(window, {
                                scrollTo: {
                                    y: y,
                                    autoKill: false
                                },
                                duration: 1
                            });
                        });
                    });

                    let maxWidth = 0;

                    const getMaxWidth = () => {
                        maxWidth = 0;
                        sections.forEach((section) => {
                            maxWidth += section.offsetWidth;
                        });
                    };

                    getMaxWidth();

                    ScrollTrigger.addEventListener('refreshInit', getMaxWidth);

                    tween = gsap.to(sections, {
                        x: () => `-${maxWidth - window.innerWidth}`,
                        ease: "none",
                        scrollTrigger: {
                            trigger: '.screen-wrapper',
                            pin: true,
                            scrub: true,
                            end: () => `+=${maxWidth}`,
                            invalidateOnRefresh: true
                        }
                    });

                    sections.forEach((sct, i) => {
                        ScrollTrigger.create({
                            trigger: sct,
                            start: () => 'top top-=' + (sct.offsetLeft - window.innerWidth/2) * (maxWidth / (maxWidth - window.innerWidth)),
                            end: () => '+=' + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
                            toggleClass: {targets: sct, className: 'active'}
                        });
                    });

                },
            });

            ScrollTrigger.matchMedia({
                '(max-width: 768px), (max-height: 900px)': () => {
                    console.log('Screen - vertical mode');
                },
            });
        //}
    };
}

window.App = new App();